import { useHistory } from "react-router-dom";
import { useCallback, useContext } from "react";
import UserContext from "../../common/UserContext";
import { patchAPI, postAPI } from "../../../WebAPI";
import { toastr } from "react-redux-toastr";
import * as _ from "lodash";

const extractValues = (xs) => (xs ?? []).map((x) => x.value);

export const makeQuery = (query, { excludeTime } = { excludeTime: false }) => {
  const result = {
    search_terms: query.query,
    ad_active_status: (query.status || {}).value,
    ad_reached_countries: extractValues(query.countries),
    search_page_ids: extractValues(query.pages),
    delivery_by_region: extractValues(query.regions),
    impression_condition: (query.impressionCondition || {}).value,
    publisher_platforms: extractValues(query.platforms),
    known: extractValues(query.known),
    search_type: query.search_type ?? "KEYWORD_UNORDERED",
    ad_delivery_date_min: _.isEmpty(query.ad_delivery_date_min)
      ? null
      : query.ad_delivery_date_min,
    ad_delivery_date_max: _.isEmpty(query.ad_delivery_date_max)
      ? null
      : query.ad_delivery_date_max,
    _raw: { ...query },
  };

  if (excludeTime) {
    delete result.ad_active_status;
    delete result.impression_condition;
    delete result.ad_delivery_date_min;
    delete result.ad_delivery_date_max;
    delete result._raw.impressionCondition;
    delete result._raw.ad_delivery_date_min;
    delete result._raw.ad_delivery_date_max;
    delete result._raw.status;
  }
  return result;
};

const TIME_FILTERS = ["ad_creation_time"];

const removeTime = (filters) =>
  _.pickBy(filters, (_, key) => !TIME_FILTERS.includes(key));

function useWatchlist({ query, watchlist, filters, results }) {
  const history = useHistory();
  const { token } = useContext(UserContext);

  const createWatchlist = useCallback(async () => {
    const data = {
      name: "New Watchlist",
      service: "facebook-ads",
      settings: {
        query: makeQuery(query, { excludeTime: true }),
        filters: removeTime(filters),
      },
      // only store a partial slice for watchlists
      sample_data: results.slice(0, Math.min(200, results.length)),
    };
    // remove time constraint on watchlist queries
    delete data.settings.query.impression_condition;
    delete data.settings.query._raw.impressionCondition;
    const watchlist = await postAPI("/api/watchlists/", data, { token });
    toastr.success("Created Watchlist", "Created new Facebook watchlist");
    history.push(`/facebook/watchlists/${watchlist.id}/`);
  }, [history, query, filters, token, results]);

  const saveWatchlist = useCallback(
    async ({ name }) => {
      const data = {
        name,
        service: watchlist.service,
        settings: {
          query: makeQuery(query, { excludeTime: true }),
          filters: removeTime(filters),
        },
        sample_data: results,
      };
      await patchAPI(`/api/watchlists/${watchlist.id}/`, data, { token });
      toastr.success("Updated Watchlist", `Updated Facebook watchlist ${name}`);
    },
    [query, filters, token, watchlist, results]
  );

  return [createWatchlist, saveWatchlist];
}

export default useWatchlist;

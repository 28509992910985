import { useContext, useMemo, useState } from "react";
import UserContext from "./UserContext";
import useAsyncEffect from "use-async-effect";
import { getAPI, postAPI } from "../../WebAPI";
import { invert, mapValues } from "lodash";
import { sleep } from "../../util";
import { dataWorker } from "./hooks";
import Worker from "workerize-loader!../workers/coordinationWorker"; // eslint-disable-line import/no-webpack-loader-syntax

export const MAX_COORDINATION_RESULTS = 20_000;

const coordinationWorker = Worker();

export function useCoordination(resultId, numResults) {
  const { updateAuth, ...user } = useContext(UserContext);
  const { token } = user;
  const [rawCoordination, setRawCoordination] = useState({});
  const [pageCoordination, setPageCoordination] = useState({});

  const pendingCount = useMemo(
    () =>
      Object.values(rawCoordination).filter((x) => x.status === "pending")
        .length,
    [rawCoordination]
  );

  useAsyncEffect(
    async (isMounted) => {
      const result =
        await coordinationWorker.makePageCoordination(rawCoordination);
      if (!isMounted()) return;
      setPageCoordination(result);
    },
    [rawCoordination]
  );

  useAsyncEffect(
    async (isMounted) => {
      setRawCoordination({});
      if (!resultId) {
        console.log("No search id");
        return;
      } else if (numResults === 0) {
        return;
      } else if (numResults > MAX_COORDINATION_RESULTS) {
        console.log(
          `Skipping coordination detection for search with more than ${MAX_COORDINATION_RESULTS} results`
        );
        return;
      }
      console.log("Fetching coordination");
      const methodTasks = await postAPI(
        `/api/coordination/${resultId}/`,
        {},
        { token }
      );
      const taskMethods = invert(methodTasks);
      setRawCoordination(mapValues(methodTasks, () => ({ status: "pending" })));
      let remaining = Array.from(Object.keys(taskMethods));
      while (remaining.length && isMounted()) {
        const status = await getAPI(`/api/coordination/${resultId}/`, token, {
          "tasks[]": remaining,
        });
        const update = {};
        for (const [taskId, taskStatus] of Object.entries(status)) {
          if (!remaining.includes(taskId)) {
            continue;
          } else if (taskStatus.status === "done") {
            console.log(`Task ${taskId} is done`);
            const value = await dataWorker.inflateSearchResults(
              taskStatus.value
            );
            console.log(`Inflated results for ${taskId}`);
            update[taskMethods[taskId]] = { status: "done", value };
          } else if (taskStatus.status === "error") {
            update[taskMethods[taskId]] = { status: "error" };
          }
          // remove what's resolved
          if (["done", "error"].includes(taskStatus.status)) {
            remaining = remaining.filter((x) => x !== taskId);
          }
        }
        if (Object.keys(update).length) {
          // console.log(update);
          setRawCoordination((prevState) => ({ ...prevState, ...update }));
        }
        await sleep(500);
      }
    },
    [resultId, token, setRawCoordination, numResults]
  );

  return [pendingCount, pageCoordination];
}

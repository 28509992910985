/** @jsxImportSource @emotion/react */
import React, { useMemo } from "react";
import * as _ from "lodash";
import { css } from "@emotion/core";
import { debounce } from "lodash";
import { Input, Label } from "reactstrap";
import { CompactMultiSelect } from "./CustomSelect";
import Switch from "react-switch";
import { DateRange } from "react-date-range";
import * as moment from "moment";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

/**
 * Convert filter definition to react-table's new array format of
 * a list of id/value.
 */
export function normalizeFilters(filters) {
  if (Array.isArray(filters)) {
    return filters;
  } else if (_.isPlainObject(filters)) {
    if (Object.keys(filters).every((x) => !isNaN(parseInt(x)))) {
      return Object.values(filters);
    }
    return Object.values(filters);
  }
}

export function dateFilterFn(rows, id, filterValue) {
  const { min: minStr, max: maxStr } = filterValue;
  const min = minStr ? moment(minStr) : null;
  const max = maxStr ? moment(maxStr) : null;
  if (min || max) {
    return rows.filter((row) => {
      const value = moment(row.values[id]);
      return (
        (!min || min.isSameOrBefore(value)) &&
        (!max || value.isSameOrBefore(max))
      );
    });
  } else {
    return rows;
  }
}

dateFilterFn.autoRemove = (val) => !val || (!val.min && !val.max);

function extractStrings(value) {
  if (typeof value === "object") {
    return Object.values(value).flatMap(extractStrings);
  } else if (Array.isArray(value)) {
    return value.flatMap(extractStrings);
  } else {
    return [String(value ?? "")];
  }
}

export function complexTextFilterFn(rows, id, filterValue) {
  const { exclude, value } = filterValue;
  if (value) {
    const query = value.toLowerCase();
    return rows.filter((row) => {
      const match = _.castArray(row.values[id])
        .flatMap(extractStrings)
        .some((x) => x.toLowerCase().includes(query));
      return exclude ? !match : match;
    });
  } else {
    return rows;
  }
}
// Let the table remove the filter if the string is empty
complexTextFilterFn.autoRemove = (val) => !val || (!val.exclude && !val.value);

export function selectFilterFn(rows, id, filterValue) {
  const { exclude, values = [] } = filterValue;
  if (values.length) {
    const query = new Set(values.map((x) => x.value));
    return rows.filter((row) => {
      const match = _.castArray(row.values[id]).some((x) =>
        query.has(typeof x === "object" ? x.value : x)
      );
      return exclude ? !match : match;
    });
  }
  return rows;
}

// Let the table remove the filter if the selection is empty
selectFilterFn.autoRemove = (val) =>
  val == null || (!val.exclude && !(val.values ?? []).length);

class TextInput extends React.Component {
  constructor(props) {
    super(props);
    this.setValue = debounce(this.setValue.bind(this), 100);
    this.state = { value: props.value };
  }

  setValue(value) {
    const { onChange } = this.props;
    onChange(value);
  }

  render() {
    const { count, className } = this.props;
    const { value } = this.state;
    return (
      <Input
        className={className}
        value={value || ""}
        onChange={(e) => {
          const x = e.target.value;
          this.setState({ value: x });
          this.setValue(x || undefined);
        }}
        placeholder={`Search ${count} records...`}
      />
    );
  }
}

export const DefaultColumnFilter = ({
  column: {
    filterValue = { exclude: false, value: "" },
    preFilteredRows,
    setFilter,
    id,
  },
}) => (
  <React.Fragment>
    <TextInput
      className="mb-2"
      count={preFilteredRows.length}
      onChange={(value) => setFilter({ ...filterValue, value })}
      value={filterValue.value}
    />
    <div
      css={css`
        display: flex;
        align-items: center;
      `}
    >
      <Label htmlFor={`switch-${id}`} className="mr-2">
        Exclude records
      </Label>
      <Switch
        css={css`
          margin-bottom: 6px;
        `}
        id={`switch-${id}`}
        onChange={(exclude) => setFilter({ ...filterValue, exclude })}
        checked={filterValue.exclude}
        height={20}
        width={40}
      />
    </div>
  </React.Fragment>
);

function extent(arr, selectFunc) {
  let max = null;
  let min = null;

  for (const element of arr) {
    const value = selectFunc ? selectFunc(element) : element;
    if (!max || value > max) {
      max = value;
    }
    if (!min || value < min) {
      min = value;
    }
  }

  return [min, max];
}

export const DateFilter = ({
  column: {
    filterValue = { min: null, max: null },
    preFilteredRows,
    setFilter,
    id,
  },
}) => {
  const [minDate, maxDate] = React.useMemo(() => {
    return extent(preFilteredRows.map((x) => moment(x.values[id])));
  }, [id, preFilteredRows]);

  const filterValueToDate = (x) => (x ? moment(x).toDate() : null);
  const dateToFilterValue = (x) => (x ? moment(x).format("YYYY-MM-DD") : null);

  const [value, setValue] = React.useState({
    min: filterValueToDate(filterValue.min),
    max: filterValueToDate(filterValue.max),
  });

  const ranges = useMemo(() => {
    return [{ key: "selection", startDate: value.min, endDate: value.max }];
  }, [value]);

  return (
    <React.Fragment>
      <DateRange
        ranges={ranges}
        startDatePlaceholder="All Earlier"
        endDatePlaceholder="All Later"
        onChange={(range) => {
          console.debug("Selected range", { range });
          const { startDate, endDate } = range.selection;
          setValue({
            min: startDate,
            max: endDate,
          });
          setFilter({
            min: startDate !== minDate ? dateToFilterValue(startDate) : null,
            max: endDate !== maxDate ? dateToFilterValue(endDate) : null,
          });
        }}
      />
    </React.Fragment>
  );
};

const makeOptions = (rows, accessor) => {
  const seen = new Set();
  const values = rows.flatMap((row) => _.castArray(accessor(row.original)));
  const result = [];
  for (const value of values) {
    if (typeof value === "object") {
      if (!value.hasOwnProperty("value") || !value.hasOwnProperty("label")) {
        throw new Error("Expected object to have value and label properties");
      }
      if (!seen.has(value.value)) {
        result.push(value);
        seen.add(value.value);
      }
    } else {
      if (!seen.has(value)) {
        seen.add(value);
        result.push({ value, label: value ?? "<NO VALUE>" });
      }
    }
  }
  return _.sortBy(result, "label");
};

export const MultiSelectColumnFilter = ({
  column: {
    filterValue = { exclude: false, values: [] },
    preFilteredRows,
    setFilter,
    id,
    accessor,
  },
}) => {
  console.debug(`Filter value ${id}`, filterValue);

  const options = React.useMemo(
    () => makeOptions(preFilteredRows, accessor),
    [preFilteredRows, accessor]
  );

  return (
    <React.Fragment>
      <CompactMultiSelect
        isSearchable
        className="react-select-container mb-2"
        classNamePrefix="react-select"
        options={options}
        value={filterValue.values}
        onChange={(values) => {
          const update = {
            ...filterValue,
            values: values ? _.castArray(values) : undefined,
          };
          console.debug(`Update filter ${id}`, update);
          setFilter(update);
        }}
      />
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <Label htmlFor={`switch-${id}`} className="mr-2">
          Exclude selected
        </Label>
        <Switch
          css={css`
            margin-bottom: 6px;
          `}
          id={`switch-${id}`}
          onChange={(exclude) => setFilter({ ...filterValue, exclude })}
          checked={filterValue.exclude}
          height={20}
          width={40}
        />
      </div>
    </React.Fragment>
  );
};
